<template>
  <div :class="twMerge(carousel_classes?.root_class, props.appendClass)">
    <div :class="twMerge(carousel_classes?.viewport_class)" ref="emblaRef">
      <div
        :class="
          twMerge(
            carousel_classes?.container_class,
            props.layout === 'vertical' && carousel_classes?.vertical?.container_class,
            props.containerClass
          )
        "
      >
        <slot> </slot>
      </div>
    </div>
    <div v-if="props.navigation || props.pagination" class="embla__controls">
      <div v-if="props.navigation" class="embla__buttons">
        <button
          v-if="!prev_btn_disabled"
          type="button"
          :class="
            twMerge(
              carousel_classes?.button_class,
              props.layout === 'vertical'
                ? carousel_classes?.vertical?.prev_button_class
                : carousel_classes?.prev_button_class,
              prev_btn_disabled && carousel_classes.disabled_button_class,
              props.prevButtonClass
            )
          "
          @click="onPrevButtonClick"
          :disabled="prev_btn_disabled"
        >
          <Icon
            :name="props.layout === 'vertical' ? 'uil:angle-up' : 'uil:angle-left-b'"
          />
        </button>
        <button
          v-if="!next_btn_disabled"
          type="button"
          :class="
            twMerge(
              carousel_classes?.button_class,
              props.layout === 'vertical'
                ? carousel_classes?.vertical?.next_button_class
                : carousel_classes?.next_button_class,
              next_btn_disabled && carousel_classes.disabled_button_class,
              props.nextButtonClass
            )
          "
          @click="onNextButtonClick"
          :disabled="next_btn_disabled"
        >
          <Icon
            :name="props.layout === 'vertical' ? 'uil:angle-down' : 'uil:angle-right-b'"
          />
        </button>
      </div>
      <div
        v-if="scroll_snaps.length > 1 && props.pagination && props.layout !== 'vertical'"
        :class="twMerge(carousel_classes?.dot_buttons_container_class)"
      >
        <button
          v-for="(snap, index) in scroll_snaps"
          :key="snap"
          type="button"
          @click="() => onDotButtonClick(index)"
          :class="
            twMerge(
              carousel_classes?.dot_button_class,
              selected_index === index && carousel_classes?.active_dot_button_class
            )
          "
        ></button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { twMerge } from "tailwind-merge";
import emblaCarouselVue from "embla-carousel-vue";
import Autoplay from "embla-carousel-autoplay";
import AutoScroll from "embla-carousel-auto-scroll";
import {
  canScrollNext,
  canScrollPrev,
  scrollNext,
  scrollPrev,
  scrollSnapList,
  scrollTo,
  selectedScrollSnap,
} from "../../utils/carouselUtils";

// Props types
type Props = {
  carouselRef?: any;
  carouselApi?: any;
  layout?: "horizontal" | "vertical";
  navigation?: boolean;
  pagination?: boolean;
  options?: object;
  active?: boolean;
  axis?: "x" | "y";
  align?: "start" | "center" | "end";
  breakpoints?: object;
  direction?: "ltr" | "rtl";
  loop?: boolean;
  dragFree?: boolean;
  autoplay?: boolean;
  autoscroll?: boolean;
  autoscrollOption?: object;
  autoscrollDirection?: "forward" | "backward";
  appendClass?: string;
  viewportClass?: string;
  containerClass?: string;
  buttonClass?: string;
  prevButtonClass?: string;
  nextButtonClass?: string;
  dotButtonsContainerClass?: string;
  dotButtonClass?: string;
  activeDotButtonClass?: string;
};

// Define props with default values
const props = withDefaults(defineProps<Props>(), {
  layout: "horizontal",
  options: () => ({}),
  autoscrollOption: () => ({
    speed: 0.4,
    stopOnInteraction: false,
  }),
  active: true,
  navigation: true,
  pagination: true,
});

const selected_index = ref(0);
const scroll_snaps = ref([]);
const prev_btn_disabled = ref(true);
const next_btn_disabled = ref(true);

// Carousel Options
const options = computed(() => ({
  ...props.options,
  ...(!props.active && { active: false }),
  ...(props.align && { align: props.align }),
  ...(props.layout === "vertical" && { axis: "y" }),
  ...(props.layout === "horizontal" && { axis: "x" }),
  ...(props.loop && { loop: true }),
  ...(props.dragFree && { dragFree: true }),
  inViewThreshold: 0.5,
}));

// AutoScroll Options
const autoscroll_options = computed(() => ({
  ...props.autoscrollOption,
  ...(props.autoscrollDirection === "backward" && { direction: "backward" }),
}));

// Carousel Plugins
const plugins = computed(() => {
  const activePlugins = [];

  if (props.autoplay) activePlugins.push(Autoplay());

  if (props.autoscroll) activePlugins.push(AutoScroll(autoscroll_options.value));

  return activePlugins;
});

// emblaCarousel ref
const [emblaRef, emblaApi] = emblaCarouselVue(options.value, plugins.value);

defineExpose({
  emblaRef,
  emblaApi,
});

const onPrevButtonClick = () => {
  scrollPrev(emblaApi);
};

const onNextButtonClick = () => {
  scrollNext(emblaApi);
};

const onDotButtonClick = (index: number) => {
  scrollTo(emblaApi, index);
};

const onInit = () => {
  if (emblaApi.value) {
    scroll_snaps.value = scrollSnapList(props.carouselApi || emblaApi);
  }
};

const onSelect = () => {
  if (emblaApi.value) {
    selected_index.value = selectedScrollSnap(emblaApi);
    prev_btn_disabled.value = !canScrollPrev(emblaApi);
    next_btn_disabled.value = !canScrollNext(emblaApi);
    inView();
  }
};

const inView = () => {
  if (emblaApi.value) {
    let inView = emblaApi.value.slidesInView();
    emit("inView", inView);
  }
};

const emit = defineEmits(["inView"]);

// Carousel classes
const carousel_classes: Record<any, any> = {
  root_class: "embla relative group px-2.5",
  viewport_class: twMerge("embla__viewport overflow-hidden", props.viewportClass),
  container_class: twMerge("embla__container flex", props.containerClass),
  button_class: twMerge(
    "embla__button h-10 w-10 rounded-full text-2xl bg-natural-soft-white shadow-[1px_5px_16px_1px_#0B3E6C1F] border border-natural-light-grey flex items-center justify-center transition-all duration-500 absolute opacity-0 group-hover:opacity-100",
    props.pagination && "-mt-[13px]",
    props.buttonClass
  ),
  disabled_button_class:
    "bg-natural-light-grey border-natural-silver-grey text-natural-silver-grey cursor-not-allowed",
  prev_button_class:
    "embla__button--prev top-1/2 left-[1rem] -translate-y-1/2 group-hover:-translate-x-1/2",
  next_button_class:
    "embla__button--next top-1/2 right-[1rem] -translate-y-1/2 group-hover:translate-x-1/2",
  dot_buttons_container_class: twMerge(
    "embla__dots mt-4 flex items-center justify-center gap-1",
    props.dotButtonsContainerClass
  ),
  dot_button_class: twMerge(
    "h-1.5 w-1.5 rounded-full bg-[#B2B3B6]",
    props.dotButtonClass
  ),
  active_dot_button_class: twMerge(
    "h-2.5 w-2.5 bg-[#36393E]",
    props.activeDotButtonClass
  ),
  vertical: {
    container_class: "flex-col min-h-[200px]",
    prev_button_class: "left-1/2 top-0 -translate-x-1/2 group-hover:-translate-y-1/2",
    next_button_class: "left-1/2 bottom-0 -translate-x-1/2 group-hover:translate-y-1/2",
  },
};

onMounted(() => {
  if (emblaApi.value) {
    onInit();
    onSelect();
    inView();
    emblaApi.value?.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }
});
</script>
